export const QRWithLogo = ({ color, filter }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      shapeRendering="crispEdges"
      viewBox="0 0 23 23"
    >
      <rect x="0" y="0" height="23" width="23" fill="#FFF" />
      <rect x="1" y="1" height="1" width="7" fill={color} />
      <rect x="9" y="1" height="1" width="1" fill={color} />
      <rect x="15" y="1" height="1" width="7" fill={color} />
      <rect x="1" y="2" height="6" width="1" fill={color} />
      <rect x="7" y="2" height="6" width="1" fill={color} />
      <rect x="10" y="2" height="1" width="2" fill={color} />
      <rect x="13" y="2" height="1" width="1" fill={color} />
      <rect x="15" y="2" height="6" width="1" fill={color} />
      <rect x="21" y="2" height="6" width="1" fill={color} />
      <rect x="3" y="3" height="3" width="3" fill={color} />
      <rect x="17" y="3" height="3" width="3" fill={color} />
      <rect x="11" y="4" height="2" width="1" fill={color} />
      <rect x="9" y="5" height="1" width="2" fill={color} />
      <rect x="12" y="5" height="2" width="2" fill={color} />
      <rect x="9" y="6" height="2" width="1" fill={color} />
      <rect x="2" y="7" height="1" width="5" fill={color} />
      <rect x="11" y="7" height="1" width="1" fill={color} />
      <rect x="13" y="7" height="1" width="1" fill={color} />
      <rect x="16" y="7" height="1" width="5" fill={color} />
      <rect x="10" y="8" height="1" width="1" fill={color} />
      <rect x="2" y="9" height="1" width="7" fill={color} />
      <rect x="16" y="9" height="1" width="2" fill={color} />
      <rect x="21" y="9" height="1" width="1" fill={color} />
      <rect x="1" y="10" height="1" width="5" fill={color} />
      <rect x="16" y="10" height="1" width="1" fill={color} />
      <rect x="19" y="10" height="4" width="1" fill={color} />
      <rect x="1" y="11" height="1" width="2" fill={color} />
      <rect x="4" y="11" height="2" width="1" fill={color} />
      <rect x="7" y="11" height="1" width="2" fill={color} />
      <rect x="17" y="11" height="1" width="2" fill={color} />
      <rect x="20" y="11" height="1" width="2" fill={color} />
      <rect x="2" y="12" height="1" width="1" fill={color} />
      <rect x="5" y="12" height="2" width="1" fill={color} />
      <rect x="8" y="12" height="1" width="1" fill={color} />
      <rect x="16" y="12" height="1" width="2" fill={color} />
      <rect x="21" y="12" height="2" width="1" fill={color} />
      <rect x="1" y="13" height="1" width="1" fill={color} />
      <rect x="6" y="13" height="1" width="2" fill={color} />
      <rect x="17" y="13" height="1" width="2" fill={color} />
      <rect x="20" y="13" height="1" width="1" fill={color} />
      <rect x="15" y="14" height="3" width="1" fill={color} />
      <rect x="18" y="14" height="1" width="1" fill={color} />
      <rect x="1" y="15" height="1" width="7" fill={color} />
      <rect x="9" y="15" height="6" width="1" fill={color} />
      <rect x="11" y="15" height="1" width="2" fill={color} />
      <rect x="16" y="15" height="1" width="1" fill={color} />
      <rect x="1" y="16" height="6" width="1" fill={color} />
      <rect x="7" y="16" height="6" width="1" fill={color} />
      <rect x="14" y="16" height="1" width="1" fill={color} />
      <rect x="18" y="16" height="1" width="1" fill={color} />
      <rect x="3" y="17" height="3" width="3" fill={color} />
      <rect x="10" y="17" height="3" width="1" fill={color} />
      <rect x="13" y="17" height="2" width="1" fill={color} />
      <rect x="16" y="17" height="2" width="1" fill={color} />
      <rect x="19" y="17" height="5" width="1" fill={color} />
      <rect x="11" y="18" height="3" width="1" fill={color} />
      <rect x="12" y="19" height="1" width="1" fill={color} />
      <rect x="14" y="19" height="1" width="1" fill={color} />
      <rect x="17" y="19" height="1" width="2" fill={color} />
      <rect x="16" y="20" height="1" width="2" fill={color} />
      <rect x="2" y="21" height="1" width="5" fill={color} />
      <rect x="12" y="21" height="1" width="1" fill={color} />
      <rect x="14" y="21" height="1" width="1" fill={color} />
      <rect x="17" y="21" height="1" width="2" fill={color} />
      <rect x="20" y="21" height="1" width="1" fill={color} />
      <image
        xlinkHref="https://cdn.jsdelivr.net/gh/RistekCSUI/cdn@main/assets/2024/images/logos/ristek-black.png"
        filter={filter}
        x="10"
        y="10"
        width="4"
        height="4"
      />
    </svg>
  );
};

export const QRWithoutLogo = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      shapeRendering="crispEdges"
      viewBox="0 0 23 23"
    >
      <rect x="0" y="0" height="23" width="23" fill="#FFF" />
      <rect x="1" y="1" height="1" width="7" fill={color} />
      <rect x="9" y="1" height="1" width="1" fill={color} />
      <rect x="15" y="1" height="1" width="7" fill={color} />
      <rect x="1" y="2" height="6" width="1" fill={color} />
      <rect x="7" y="2" height="6" width="1" fill={color} />
      <rect x="10" y="2" height="1" width="2" fill={color} />
      <rect x="13" y="2" height="1" width="1" fill={color} />
      <rect x="15" y="2" height="6" width="1" fill={color} />
      <rect x="21" y="2" height="6" width="1" fill={color} />
      <rect x="3" y="3" height="3" width="3" fill={color} />
      <rect x="17" y="3" height="3" width="3" fill={color} />
      <rect x="11" y="4" height="2" width="1" fill={color} />
      <rect x="9" y="5" height="1" width="2" fill={color} />
      <rect x="12" y="5" height="2" width="2" fill={color} />
      <rect x="9" y="6" height="2" width="1" fill={color} />
      <rect x="2" y="7" height="1" width="5" fill={color} />
      <rect x="11" y="7" height="1" width="1" fill={color} />
      <rect x="13" y="7" height="1" width="1" fill={color} />
      <rect x="16" y="7" height="1" width="5" fill={color} />
      <rect x="10" y="8" height="3" width="1" fill={color} />
      <rect x="2" y="9" height="1" width="7" fill={color} />
      <rect x="12" y="9" height="1" width="1" fill={color} />
      <rect x="16" y="9" height="1" width="2" fill={color} />
      <rect x="21" y="9" height="1" width="1" fill={color} />
      <rect x="1" y="10" height="1" width="5" fill={color} />
      <rect x="16" y="10" height="1" width="1" fill={color} />
      <rect x="19" y="10" height="4" width="1" fill={color} />
      <rect x="1" y="11" height="1" width="2" fill={color} />
      <rect x="4" y="11" height="2" width="1" fill={color} />
      <rect x="7" y="11" height="1" width="3" fill={color} />
      <rect x="13" y="11" height="1" width="2" fill={color} />
      <rect x="17" y="11" height="1" width="2" fill={color} />
      <rect x="20" y="11" height="1" width="2" fill={color} />
      <rect x="2" y="12" height="1" width="1" fill={color} />
      <rect x="5" y="12" height="2" width="1" fill={color} />
      <rect x="8" y="12" height="1" width="1" fill={color} />
      <rect x="10" y="12" height="1" width="1" fill={color} />
      <rect x="12" y="12" height="1" width="1" fill={color} />
      <rect x="16" y="12" height="1" width="2" fill={color} />
      <rect x="21" y="12" height="2" width="1" fill={color} />
      <rect x="1" y="13" height="1" width="1" fill={color} />
      <rect x="6" y="13" height="1" width="2" fill={color} />
      <rect x="9" y="13" height="8" width="1" fill={color} />
      <rect x="11" y="13" height="1" width="1" fill={color} />
      <rect x="14" y="13" height="2" width="1" fill={color} />
      <rect x="17" y="13" height="1" width="2" fill={color} />
      <rect x="20" y="13" height="1" width="1" fill={color} />
      <rect x="10" y="14" height="1" width="1" fill={color} />
      <rect x="12" y="14" height="2" width="1" fill={color} />
      <rect x="15" y="14" height="3" width="1" fill={color} />
      <rect x="18" y="14" height="1" width="1" fill={color} />
      <rect x="1" y="15" height="1" width="7" fill={color} />
      <rect x="11" y="15" height="1" width="1" fill={color} />
      <rect x="16" y="15" height="1" width="1" fill={color} />
      <rect x="1" y="16" height="6" width="1" fill={color} />
      <rect x="7" y="16" height="6" width="1" fill={color} />
      <rect x="14" y="16" height="1" width="1" fill={color} />
      <rect x="18" y="16" height="1" width="1" fill={color} />
      <rect x="3" y="17" height="3" width="3" fill={color} />
      <rect x="10" y="17" height="3" width="1" fill={color} />
      <rect x="13" y="17" height="2" width="1" fill={color} />
      <rect x="16" y="17" height="2" width="1" fill={color} />
      <rect x="19" y="17" height="5" width="1" fill={color} />
      <rect x="11" y="18" height="3" width="1" fill={color} />
      <rect x="12" y="19" height="1" width="1" fill={color} />
      <rect x="14" y="19" height="1" width="1" fill={color} />
      <rect x="17" y="19" height="1" width="2" fill={color} />
      <rect x="16" y="20" height="1" width="2" fill={color} />
      <rect x="2" y="21" height="1" width="5" fill={color} />
      <rect x="12" y="21" height="1" width="1" fill={color} />
      <rect x="14" y="21" height="1" width="1" fill={color} />
      <rect x="17" y="21" height="1" width="2" fill={color} />
      <rect x="20" y="21" height="1" width="1" fill={color} />
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L2 9L12 16"
        stroke="#5038BC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ThreeCircle = () => {
  return (
    <svg
      width="30"
      height="8"
      viewBox="0 0 22 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="2" stroke="#5038BC" />
      <circle cx="11" cy="3" r="2" stroke="#5038BC" />
      <circle cx="19" cy="3" r="2" stroke="#5038BC" />
    </svg>
  );
};
