import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import Button from "../../common/Button";
import { LeftArrow } from "./Logo";

const BackSection = ({ onClick, text, additionStyles }) => {
  const { setState, setDataLink } = useStateManagementContext();
  const { setDrawerOpened } = useDrawerContext();
  return (
    <div className={additionStyles}>
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            setDrawerOpened(true);
            setDataLink();
            setState("landing");
          }
        }}
        additionStyle={"!px-0 !flex !gap-2 !items-center"}
        type={"ghost"}
      >
        <LeftArrow /> {text}
      </Button>
    </div>
  );
};

export default BackSection;
